import axios from "axios";
export const LoginApi = async (data) => {
  return axios.post(`${process.env.REACT_APP_API}users/login`, data);
};
export const LogoutApi = async (token, data = {}) => {
  return axios.post(`${process.env.REACT_APP_API}users/logout`, data, {
    headers: {
      authorization: `${token}`,
    },
  });
};
export const SignupApi = async (data) => {
  return axios.post(`${process.env.REACT_APP_API}users/signup`, data);
};
export const GoogleApi = async (data) => {
  return axios.post(`${process.env.REACT_APP_API}users/google`, data);
};
export const GetAllUsers = async (token, page, limit) => {
  return axios.get(
    `${process.env.REACT_APP_API}admin/users?limit=${limit || 10}&page_no=${page || 1
    }`,
    {
      headers: {
        authorization: `${token}`,
      },
    }
  );
};
export const GetAllBlockedUsers = async (token, page, limit) => {
  return axios.get(
    `${process.env.REACT_APP_API}admin/blocked-users?limit=${limit || 10
    }&page_no=${page || 1}`,
    {
      headers: {
        authorization: `${token}`,
      },
    }
  );
};
export const GetAllServices = async (token, page, limit) => {
  return axios.get(
    `${process.env.REACT_APP_API}service/all-services?limit=${limit || 10
    }&page=${page || 1}`,
    {
      headers: {
        authorization: `${token}`,
      },
    }
  );
};
export const DeleteServices = async (id, token) => {
  return axios.delete(`${process.env.REACT_APP_API}service/${id}`, {
    headers: {
      authorization: `${token}`,
    },
  });
};
export const AddService = async (data, token) => {
  return axios.post(
    `${process.env.REACT_APP_API}service/add-service`,
    data,
    {
      headers: {
        authorization: `${token}`,
      },
    }
  );
};
export const EditService = async (id, data, token) => {
  return axios.patch(
    `${process.env.REACT_APP_API}service/update/${id}`,
    data,
    {
      headers: {
        authorization: `${token}`,
      },
    }
  );
};
export const CreateUser = async (token, data) => {
  return axios.post(
    `${process.env.REACT_APP_API}admin/users/create`,
    data,
    {
      headers: {
        authorization: `${token}`,
      },
    }
  );
};
export const SetBlock = async (data, token) => {
  return axios.patch(
    `${process.env.REACT_APP_API}admin/users/toggleBlock`,
    data,
    {
      headers: {
        authorization: `${token}`,
      },
    }
  );
};
export const ForgotApi = async (data) => {
  return axios.post(`${process.env.REACT_APP_API}users/forgot`, data);
};
// Otp main apis
export const GenerateNumberAPI = async (data, token) => {
  return axios.post(
    `${process.env.REACT_APP_API}mobile/generate`,
    data,
    {
      headers: {
        authorization: `${token}`,
      },
    }
  );
};
export const GenerateOtpAPI = async (data, token) => {
  return axios.post(`${process.env.REACT_APP_API}otp/generate`, data, {
    headers: {
      authorization: `${token}`,
    },
  });
};
export const CancelOtpAPI = async (data, token) => {
  return axios.post(`${process.env.REACT_APP_API}otp/cancelOtp`, data, {
    headers: {
      authorization: `${token}`,
    },
  });
};
export const GetOtpHistoryAPI = async (token, page, limit, time) => {
  return axios.get(
    `${process.env.REACT_APP_API}otp/mobile-history?limit=${limit || 10}&page=${page || 1}&time=${time || 'all'}`,
    {
      headers: {
        authorization: `${token}`,
      },
    }
  );
};
// Transaction
export const SubmitTransactionAPI = async (token, data) => {
  return axios.post(
    `${process.env.REACT_APP_API}payment/transaction`,
    data,
    {
      headers: {
        authorization: `${token}`,
      },
    }
  );
};
export const GetTransactionAPI = async (token, page, limit) => {
  return axios.get(
    `${process.env.REACT_APP_API}payment/transaction-history?limit=${limit || 10
    }&page=${page || 1}`,
    {
      headers: {
        authorization: `${token}`,
      },
    }
  );
};
// Balance API
export const GetBalanceAPI = async (token) => {
  return axios.get(`${process.env.REACT_APP_API}payment/balance`, {
    headers: {
      authorization: `${token}`,
    },
  });
};
export const EditBalance = async (token, data) => {
  return axios.patch(
    `${process.env.REACT_APP_API}admin/user/balance/`,
    data,
    {
      headers: {
        authorization: `${token}`,
      },
    }
  );
};
//Admin All and single user
export const GetAdminAllUsers = async (token) => {
  return axios.get(`${process.env.REACT_APP_API}admin/all-users`, {
    headers: {
      authorization: `${token}`,
    },
  });
};
export const GetAdminSearchUser = async (token, email) => {
  return axios.get(`${process.env.REACT_APP_API}admin/search-users?email=${email}`, {
    headers: {
      authorization: `${token}`,
    },
  });
};
export const GetAdminSingleUser = async (token, id) => {
  return axios.get(`${process.env.REACT_APP_API}admin/user/${id}`, {
    headers: {
      authorization: `${token}`,
    },
  });
};

// Admin All and single service
export const GetAdminAllServices = async (token) => {
  return axios.get(`${process.env.REACT_APP_API}admin/all-services`, {
    headers: {
      authorization: `${token}`,
    },
  });
};

export const GetAdminSingleService = async (token, id) => {
  return axios.get(`${process.env.REACT_APP_API}admin/service/${id}`, {
    headers: {
      authorization: `${token}`,
    },
  });
};
// Admin get single user details
export const GetSingleUserDetails = async (token, data, page, limit) => {
  return axios.post(
    `${process.env.REACT_APP_API}admin/userDetails?limit=${limit || 10}&page=${page || 1
    }`,
    data,
    {
      headers: {
        authorization: `${token}`,
      },
    }
  );
};
//API token 
export const GenerateTokenAPI = async (token) => {
  return axios.post(
    `${process.env.REACT_APP_API}api/generateToken`, {},
    {
      headers: {
        authorization: `${token}`,
      },
    }
  );
};

export const GetTokenAPI = async (token) => {
  return axios.get(
    `${process.env.REACT_APP_API}api/getToken`, {
    headers: {
      authorization: `${token}`,
    },
  }
  );
};

// Currency API
export const AddCurrency = async (data, token) => {
  return axios.post(
    `${process.env.REACT_APP_API}admin/currency`,
    data,
    {
      headers: {
        authorization: `${token}`,
      },
    }
  );
};
export const GetAdminAllCurrency = async (token) => {
  return axios.get(`${process.env.REACT_APP_API}admin/allcurrency`, {
    headers: {
      authorization: `${token}`,
    },
  });
};
export const EditCurrency = async (id, data, token) => {
  return axios.patch(
    `${process.env.REACT_APP_API}admin/currency/${id}`,
    data,
    {
      headers: {
        authorization: `${token}`,
      },
    }
  );
};
export const GetAllCurrency = async (token) => {
  return axios.get(`${process.env.REACT_APP_API}payment/allcurrency`, {
    headers: {
      authorization: `${token}`,
    },
  });
};
export const GetUpi = async (token) => {
  return axios.get(`${process.env.REACT_APP_API}payment/upi`, {
    headers: {
      authorization: `${token}`,
    },
  });
};

// Discount Services 

export const getDiscountServices = async (token) => {
  return axios.get(`${process.env.REACT_APP_API}admin/current-discount`, {
    headers: {
      authorization: `${token}`,
    },
  });
};
export const EditDiscount = async (token, data) => {
  return axios.patch(
    `${process.env.REACT_APP_API}admin/discount-update`,
    data,
    {
      headers: {
        authorization: `${token}`,
      },
    }
  );
};
export const GlobalDiscountUpdate = async (token, data) => {
  return axios.patch(
    `${process.env.REACT_APP_API}admin/global-discount-update`,
    data,
    {
      headers: {
        authorization: `${token}`,
      },
    }
  );
};
export const getDiscountServicesUser = async (token) => {
  return axios.get(`${process.env.REACT_APP_API}service/current-discount`, {
    headers: {
      authorization: `${token}`,
    },
  });
};
//UPI Update
export const EditUpi = async (token, data) => {
  return axios.patch(
    `${process.env.REACT_APP_API}admin/upi`,
    data,
    {
      headers: {
        authorization: `${token}`,
      },
    }
  );
};
export const GetUPI = async (token) => {
  return axios.get(
    `${process.env.REACT_APP_API}admin/upi`,
    {
      headers: {
        authorization: `${token}`,
      },
    }
  );
};
export const AdminDailyStatsAPI = async (token) => {
  return axios.get(`${process.env.REACT_APP_API}admin/service-dailystats`, {
    headers: {
      authorization: `${token}`,
    },
  });
};
//coupon code apis
export const AdminGetAllCoupons = async (token, page, limit) => {
  return axios.get(
    `${process.env.REACT_APP_API}coupon/all-coupons?limit=${limit || 10
    }&page=${page || 1}`,
    {
      headers: {
        authorization: `${token}`,
      },
    }
  );
};
export const AdminCreateCoupons = async (data, token) => {
  return axios.post(
    `${process.env.REACT_APP_API}coupon/generate`,
    data,
    {
      headers: {
        authorization: `${token}`,
      },
    }
  );
};
export const DeleteCoupon = async (id, token) => {
  return axios.delete(`${process.env.REACT_APP_API}coupon/${id}`, {
    headers: {
      authorization: `${token}`,
    },
  });
};
export const UseCouponCode = async (token, data) => {
  return axios.post(
    `${process.env.REACT_APP_API}coupon/usecoupon`,
    data,
    {
      headers: {
        authorization: `${token}`,
      },
    }
  );
};
export const AdminGetAllTransaction = async (token, page, limit) => {
  return axios.get(
    `${process.env.REACT_APP_API}admin/all-transactions?limit=${limit || 10}&page_no=${page || 1
    }`,
    {
      headers: {
        authorization: `${token}`,
      },
    }
  );
};
export const GetAdminSearchTransaction = async (token, transactionId) => {
  return axios.get(`${process.env.REACT_APP_API}admin/search-transactions?transactionId=${transactionId}`, {
    headers: {
      authorization: `${token}`,
    },
  });
};
export const GetAdminSingleTransaction = async (token, id) => {
  return axios.get(`${process.env.REACT_APP_API}admin/transaction/${id}`, {
    headers: {
      authorization: `${token}`,
    },
  });
};
//default server
export const DefaultServer = async (token, defaultServer) => {
  return axios.patch(
    `${process.env.REACT_APP_API}users/defaultserver`, { defaultServer },
    {
      headers: {
        authorization: `${token}`,
      },
    }
  );
};

export const CheckOTPAPI = async (socket, payload) => {
  return new Promise((resolve, reject) => {
    let responseData = null; // Store the full response

    // If the socket is already open, send the payload immediately
    const sendPayload = () => {
      if (socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify(payload));
      } else {
        console.error("WebSocket is not open.");
        reject("WebSocket is not open!");
      }
    };

    // Handle messages from server
    socket.onmessage = (event) => {
      responseData = JSON.parse(event.data); // Store full response
    };

    // Handle errors
    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
      reject(error);
    };

    // Handle socket closure
    socket.onclose = (event) => {
      console.log(`WebSocket closed. Code: ${event.code}, Reason: ${event.reason}`);
      resolve(responseData); // Return collected response
    };

    // If already open, send payload immediately
    if (socket.readyState === WebSocket.OPEN) {
      sendPayload();
    } else {
      // If not open, wait for 'open' event
      socket.onopen = () => {
        console.log("WebSocket connected.");
        sendPayload();
      };
    }
  });
};