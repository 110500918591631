import React, { useContext, useState } from "react";
import { Formik, Field } from "formik";
import { BiHide, BiShow } from "react-icons/bi";
import loginSVG from "../../assets/login.svg";
import {
  Box,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  InputRightElement,
  Text,
  Input,
  VStack,
  InputGroup,
  Button,
  HStack,
  useToast,
  Link as ChakraLink

} from "@chakra-ui/react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { LoginApi } from "../../utils/api";
import { FaTelegram } from 'react-icons/fa';
import { Helmet } from "react-helmet";
const Login = () => {
  const [data, setData] = useState({ email: "", password: "" });
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const { signInWithGoogle, loginUser, setIsAdmin, isAdmin, isAuth } =
    useContext(AppContext);
  if (isAuth) {
    return <Navigate to="/" />;
  }
  if (isAdmin) {
    return <Navigate to="/admin" />;
  }
  return (
    <>
      <Helmet>
        <title>Login | Techy India</title>
      </Helmet>
      <HStack
        flexDirection={["column", "column", "row", "row"]}
        minH={"100vh"}
        p={"0 1rem"}
        mb={['1rem', '1rem', 0, 0]}
        justify={"space-between"}
      >
        <Image w={["60%", "55%", "45%", "45%"]} src={loginSVG} />
        <Box
          textAlign="left"
          p={["0rem 0rem", "0rem 1.8rem", "0rem 2.5rem", "0rem 2.5rem"]}
          pt={"1.15rem !important"}
          w={["98%", "85%", "40%", "40%"]}
          margin="auto"
          rounded={"0.3rem"}
          border={"1px solid black"}
          minH={"90vh"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center !important"}
        >
          <Text
            fontSize={["1.5rem", "1.6rem", "1.8rem", "1.8rem"]}
            mb={"1rem"}
            textAlign={"center"}
            fontWeight={500}
          >
            Login in to your account
          </Text>
          <Flex justify="space-around">
            <Flex
              align="center"
              bg="white"
              color="black"
              cursor={"pointer"}
              rounded={"0.5rem"}
              w={"90%"}
              p={2}
              justify={"center"}
              onClick={signInWithGoogle}
            // _hover={{ background: "#ffebd7" }}
            >
              <Image
                w={["25px", "28px"]}
                src={"https://img.icons8.com/color/452/google-logo.png"}
              />
              <Text
                ml={["7px", "10px"]}
                fontSize={["md"]}
                fontWeight="bold"
              >
                Sign in with google
              </Text>
            </Flex>
          </Flex>
          <Flex align="center" justify="space-around" mt={8}>
            <Box borderBottom="2px solid gray" w="42%"></Box>
            <Text fontWeight="bold">OR</Text>
            <Box borderBottom="2px solid gray" w="42%"></Box>
          </Flex>
          <Box>
            <Flex align="center" justify="center" my={5}>
              <Box rounded="md" w="90%" pb={0}>
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  onSubmit={(values) => {
                    setData(values);
                    // console.log(process.env.REACT_APP_ADMIN_ID);
                    if (values.email == process.env.REACT_APP_ADMIN_ID) {
                      LoginApi({
                        email: values.email,
                        password: values.password,
                      })
                        .then((res) => {
                          console.log(res.data, "my response");
                          if (res.data.role == "admin") {
                            localStorage.setItem(
                              "Token",
                              JSON.stringify(res.data.token)
                            );
                            setIsAdmin(true);
                            toast({
                              title: "Admin Logged In",
                              description: `Admin logged in Successfully`,
                              status: "success",
                              duration: 4000,
                              isClosable: true,
                              position: "top",
                            });
                            setTimeout(() => {
                              navigate("/admin");
                            }, 1800);
                          }
                        })
                        .catch((err) => {
                          toast({
                            title: "Error",
                            description: err.response?.data?.message
                              ? err.response?.data.message
                              : err.message,
                            status: "error",
                            duration: 3000,
                            isClosable: true,
                            position: "top",
                          });
                        });
                    } else {
                      loginUser(values.email, values.password);
                    }
                  }}
                >
                  {({ handleSubmit, errors, touched }) => (
                    <form onSubmit={handleSubmit}>
                      <VStack spacing={8} align="flex-start">
                        <FormControl
                          position={"relative"}
                          isInvalid={!!errors.email && touched.email}
                        >
                          <FormLabel htmlFor="email">Email</FormLabel>
                          <Field
                            as={Input}
                            id="email"
                            name="email"
                            type="email"
                            variant="outline"
                            size="lg"
                            border="2.5px solid black"
                            _hover={{ background: "#ffffff", color: "black" }}
                            validate={(value) => {
                              let error;
                              if (!value) {
                                error = "Required";
                              } else if (
                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                                  value
                                )
                              ) {
                                error = "Invalid email address";
                              }
                              return error;
                            }}
                          />
                          <FormErrorMessage position={"absolute"}>
                            {errors.email}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={!!errors.password && touched.password}
                        >
                          <FormLabel htmlFor="password">Password</FormLabel>
                          <InputGroup position={"relative"}>
                            <Field
                              as={Input}
                              id="password"
                              name="password"
                              type={show ? "text" : "password"}
                              size="lg"
                              border="2.5px solid black"
                              _hover={{ background: "#ffffff", color: "black" }}
                              validate={(value) => {
                                let error;

                                if (value.length < 7) {
                                  error =
                                    "Password must contain at least 8 characters";
                                }

                                return error;
                              }}
                            />
                            <InputRightElement width="4.5rem">
                              <Center
                                fontSize="30px"
                                pt={2}
                                bg="none"
                                onClick={() => setShow(!show)}
                              >
                                {show ? (
                                  <BiHide style={{ cursor: "pointer" }} />
                                ) : (
                                  <BiShow style={{ cursor: "pointer" }} />
                                )}
                              </Center>
                            </InputRightElement>
                          </InputGroup>
                          <FormErrorMessage position={"absolute"}>
                            {" "}
                            {errors.password}
                          </FormErrorMessage>
                        </FormControl>
                        <Flex justify="right" w="100%">
                          <Link to="/forgot_password">
                            <Text
                              w="fit-content"
                              borderBottom="1px solid rgb(218 52 85)"
                              _hover={{ color: "rgb(218 52 85)" }}
                            >
                              Forgot password?
                            </Text>
                          </Link>
                        </Flex>
                        <Button
                          h="48px"
                          // background: rgb(218 52 85);
                          // color: white;
                          _hover={{
                            color: "rgb(218 52 85)",
                            background: "white",
                          }}
                          type="submit"
                          bg="rgb(218 52 85)"
                          color={"white"}
                          border="1.5px solid rgb(218 52 85) !important"
                          width="full"
                          outline={"none"}
                        // borderRadius="3xl"
                        >
                          Login
                        </Button>
                      </VStack>
                    </form>
                  )}
                </Formik>
                <Text textAlign={"center"} mt={"2rem"}>
                  Don't have an account?{" "}
                  <Link id="signup" to="/signup">
                    Register
                  </Link>{" "}
                </Text>
              </Box>
            </Flex>{" "}
          </Box>
        </Box>
        <Box zIndex={10} position={'fixed'} bottom={['0.5rem', '0.9rem', '1rem', '1rem']} right={['0.5rem', '0.9rem', '1rem', '1rem']} fontSize={'3rem'} >
          <ChakraLink href="https://t.me/techyindiaxyzofficial" target={"_blank"}>
            <FaTelegram style={{
              color: '#3390EC',
              cursor: 'pointer'
            }} />
          </ChakraLink>
        </Box>
      </HStack>
    </>
  );
};

export default Login;
